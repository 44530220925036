import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["moreContent", "buttonText", "buttonIcon"]
  static values = {
    showMoreText: String,
    showLessText: String
  }

  connect() {
    this.isOpen = false
    this.updateButton()
  }

  toggle() {
    this.isOpen = !this.isOpen
    this.moreContentTarget.classList.toggle("hidden")
    this.updateButton()
  }

  updateButton() {
    const buttonText = this.isOpen ? this.showLessTextValue || "Show Less" : this.showMoreTextValue || "Show More"
    const iconClass = this.isOpen ? "fa-chevron-up" : "fa-chevron-down"

    this.buttonTextTarget.innerHTML = buttonText
    this.buttonIconTarget.classList.remove("fa-chevron-up", "fa-chevron-down")
    this.buttonIconTarget.classList.add(iconClass)  }
}